import React from 'react';
import { navigate } from 'gatsby';
import facepaint from 'facepaint';
import { css } from '@emotion/core';

import Logo from '../assets/eyemobile_logo.inline.svg';
import CompErro404 from '../assets/ComputadorErro404.inline.svg';

const mq = facepaint(['@media screen and (min-width: 768px)']);

const wrapperBps = css(
  mq({
    flexDirection: ['column', 'row'],
    alignItems: ['center', 'flex-start'],
  }),
);

const imgBps = css(
  mq({
    marginTop: [24, 0],
  }),
);

const NotFoundPage = () => (
  <div
    style={{
      height: '100vh',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      padding: '0 24px',
    }}
  >
    <div>
      <Logo
        style={{
          display: 'block',
          margin: '0 auto',
          marginTop: 30,
          maxWidth: '100%',
          height: 'auto',
        }}
      />
    </div>
    <div
      style={{
        display: 'flex',
        maxWidth: 1880,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          maxWidth: 930,
        }}
        css={wrapperBps}
      >
        <div
          style={{
            maxWidth: 344,
          }}
        >
          <p
            style={{
              fontFamily: 'Arial, Helvetica, sans-serif',
              fontSize: 35,
              fontWeight: 'bold',
              color: '#656565',
            }}
          >
            <strong>Que Pena!</strong>
            <br />
            Parece que a tela que você procura não existe.
          </p>
          <button
            type="button"
            className="btn btn-primary"
            id="buttonHome"
            style={{
              borderRadius: 3,
              fontSize: 13,
              borderColor: 'transparent',
              outline: 'none !important',
              transition: 'all 0.1s',
              backgroundColor: '#009BDD',
            }}
            onClick={() => navigate('/')}
          >
            VOLTAR A PAGINA INICIAL
          </button>
        </div>
        <div css={imgBps}>
          <CompErro404 style={{ width: '100%' }} />
        </div>
      </div>
    </div>
  </div>
);

export default NotFoundPage;
